import { faCheck, faCheckCircle, faDiagramSuccessor, faFile, faForward, faMailForward, faPlus, faReply, faVideo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { InlineWidget } from 'react-calendly';
import { useTranslation } from 'react-i18next';

function PQPhone(props) {

  const { t } = useTranslation();

  return (
    <>
      <div style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', padding: 0, margin: 0 }}>
        <div style={{ fontSize: '8vw', fontWeight: 600, marginTop: '25vw', width: '90%', textAlign: 'center' }}>{t('pq_page.title')}</div>
        <div style={{ fontSize: '6vw', fontWeight: 100, marginTop: '3vw', color: '#9bbb59' }}>{t('pq_page.subtitle')}</div>
        <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', flexDirection: 'column' }}>

          <div className={'box-changeable show'} onClick={() => { window.open("https://vimeo.com/967264241/45f4f68488") }} style={{ width: '59.8%', marginTop: '10vw', transition: 'opacity 0.5s ease 0.5s, filter 0.5s ease 0s', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', flexDirection: 'column', backgroundImage: 'url(/background/background-box.svg)', height: '86vw', borderBottomLeftRadius: '2vw', borderBottomRightRadius: '2vw', overflow: 'hidden' }}>
            <div style={{ position: 'absolute', top: 0, right: 0, backgroundColor: '#f85531', color: 'white', padding: '1vw', borderRadius: '50%', height: '10vw', width: '10vw', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '7vw', fontWeight: 500 }}>1
            </div>
            <div style={{ fontSize: '8vw', width: '80%', textAlign: 'start', fontWeight: 600 }}>
              <div style={{ maxWidth: '10vw' }}>{t('pq_page.title1')}</div>
            </div>
            <div style={{ fontSize: '5vw', width: '80%', textAlign: 'start', marginTop: '4vw' }}>
              {t('pq_page.description1')}</div>
            <div style={{ position: 'absolute', bottom: '4vw', left: '4vw', display: 'flex', fontWeight: 600, justifyContent: 'center', alignItems: 'center', gap: '3vw', fontSize: '4.5vw', textAlign: 'start' }}>
              <FontAwesomeIcon style={{ position: 'relative', color: 'white', backgroundColor: 'black', padding: '2.5vw', width: '3.5vw', height: '3.5vw', borderRadius: '50%' }} icon={faVideo} />
              {t('pq_page.button1')}</div>
          </div>

          <div className={'box-changeable show'} onClick={() => {
            window.open("mailto:valentin.ene@ytd.ro?subject=Meeting Request&body=Hello Valentin, I would like to reserve a spot. My name is [your name] and my email is [your mail]. Thank you!");
          }} style={{ width: '59.8%', marginTop: '10vw', transition: 'opacity 0.5s ease 0.5s, filter 0.5s ease 0s', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', flexDirection: 'column', backgroundImage: 'url(/background/background-box.svg)', height: '86vw', borderBottomLeftRadius: '2vw', borderBottomRightRadius: '2vw', overflow: 'hidden' }}>
            <div style={{ position: 'absolute', top: 0, right: 0, backgroundColor: '#2b4660', color: 'white', padding: '1vw', borderRadius: '50%', height: '10vw', width: '10vw', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '7vw', fontWeight: 500 }}>2
            </div>
            <div style={{ fontSize: '8vw', width: '80%', textAlign: 'start', fontWeight: 600 }}>
              <div style={{ maxWidth: '100vw' }}>{t('pq_page.title2')}</div>
            </div>
            <div style={{ fontSize: '5vw', width: '80%', textAlign: 'start', marginTop: '4vw' }}>
              {t('pq_page.description2')}</div>
            <div style={{ position: 'absolute', bottom: '4vw', left: '4vw', display: 'flex', fontWeight: 600, justifyContent: 'center', alignItems: 'center', gap: '3vw', fontSize: '4.5vw', textAlign: 'start' }}>
              <FontAwesomeIcon style={{ position: 'relative', color: 'white', backgroundColor: 'black', padding: '2.5vw', width: '3.5vw', height: '3.5vw', borderRadius: '50%' }} icon={faMailForward} />
              {t('pq_page.button2')}</div>
          </div>

          <div className={'box-changeable show'} onClick={() => { window.open("https://www.positiveintelligence.com/saboteurs/?mwr=55b665d8") }} style={{ width: '59.8%', marginTop: '10vw', transition: 'opacity 0.5s ease 0.5s, filter 0.5s ease 0s', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', flexDirection: 'column', backgroundImage: 'url(/background/background-box.svg)', height: '86vw', borderBottomLeftRadius: '2vw', borderBottomRightRadius: '2vw', overflow: 'hidden' }}>
            <div style={{ position: 'absolute', top: 0, right: 0, backgroundColor: '#9bbb59', color: 'white', padding: '1vw', borderRadius: '50%', height: '10vw', width: '10vw', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '7vw', fontWeight: 500 }}>3
            </div>
            <div style={{ fontSize: '8vw', width: '80%', textAlign: 'start', fontWeight: 600 }}>
              <div style={{ maxWidth: '100vw' }}>{t('pq_page.title3')}</div>
            </div>
            <div style={{ fontSize: '5vw', width: '80%', textAlign: 'start', marginTop: '4vw' }}>
              {t('pq_page.description3')}</div>
            <div style={{ position: 'absolute', bottom: '4vw', left: '4vw', display: 'flex', fontWeight: 600, justifyContent: 'center', alignItems: 'center', gap: '3vw', fontSize: '4.5vw', textAlign: 'start' }}>
              <FontAwesomeIcon style={{ position: 'relative', color: 'white', backgroundColor: 'black', padding: '2.5vw', width: '3.5vw', height: '3.5vw', borderRadius: '50%' }} icon={faFile} />
              {t('pq_page.button3')}</div>
          </div>

          <div className={'box-changeable show'} onClick={() => {
            window.open("mailto:valentin.ene@ytd.ro?subject=Results&body=Hello Valentin, I've attached in this email my results. Thank you!");
          }} style={{ width: '59.8%', marginTop: '10vw', transition: 'opacity 0.5s ease 0.5s, filter 0.5s ease 0s', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', flexDirection: 'column', backgroundImage: 'url(/background/background-box.svg)', height: '86vw', borderBottomLeftRadius: '2vw', borderBottomRightRadius: '2vw', overflow: 'hidden' }}>
            <div style={{ position: 'absolute', top: 0, right: 0, backgroundColor: '#8064a2', color: 'white', padding: '1vw', borderRadius: '50%', height: '10vw', width: '10vw', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '7vw', fontWeight: 500 }}>4
            </div>
            <div style={{ fontSize: '8vw', width: '80%', textAlign: 'start', fontWeight: 600 }}>
              <div style={{ maxWidth: '100vw' }}>{t('pq_page.title4')}</div>
            </div>
            <div style={{ fontSize: '5vw', width: '80%', textAlign: 'start', marginTop: '4vw' }}>
              {t('pq_page.description4')}</div>
            <div style={{ position: 'absolute', bottom: '4vw', left: '4vw', display: 'flex', fontWeight: 600, justifyContent: 'center', alignItems: 'center', gap: '3vw', fontSize: '4.5vw', textAlign: 'start' }}>
              <FontAwesomeIcon style={{ position: 'relative', color: 'white', backgroundColor: 'black', padding: '2.5vw', width: '3.5vw', height: '3.5vw', borderRadius: '50%' }} icon={faMailForward} />
              {t('pq_page.button4')}</div>
          </div>

          <div className={'box-changeable show'} onClick={() => {
            window.open("https://calendly.com/enevali2016/30min")
          }} style={{ width: '59.8%', marginTop: '10vw', transition: 'opacity 0.5s ease 0.5s, filter 0.5s ease 0s', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', flexDirection: 'column', backgroundImage: 'url(/background/background-box.svg)', height: '86vw', borderBottomLeftRadius: '2vw', borderBottomRightRadius: '2vw', overflow: 'hidden' }}>
            <div style={{ position: 'absolute', top: 0, right: 0, backgroundColor: '#ffc524', color: 'white', padding: '1vw', borderRadius: '50%', height: '10vw', width: '10vw', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '7vw', fontWeight: 500 }}>5
            </div>
            <div style={{ fontSize: '8vw', width: '80%', textAlign: 'start', fontWeight: 600 }}>
              <div style={{ maxWidth: '100vw' }}>{t('pq_page.title5')}</div>
            </div>
            <div style={{ fontSize: '5vw', width: '80%', textAlign: 'start', marginTop: '4vw' }}>
              {t('pq_page.description5')}</div>
            <div style={{ position: 'absolute', bottom: '4vw', left: '4vw', display: 'flex', fontWeight: 600, justifyContent: 'center', alignItems: 'center', gap: '3vw', fontSize: '4.5vw', textAlign: 'start' }}>
              <FontAwesomeIcon style={{ position: 'relative', color: 'white', backgroundColor: 'black', padding: '2.5vw', width: '3.5vw', height: '3.5vw', borderRadius: '50%' }} icon={faPlus} />
              {t('pq_page.button5')}</div>
          </div>
          <div style={{ position: 'absolute', bottom: '30vw', left: '10vw', fontWeight: 600, fontSize: '10vw', opacity: 0.2 }}>{t('pq_page.motto')}</div>
          <img style={{ position: 'relative', width: '100%', marginTop: '5vw' }} src='newpq/roadclean.png' />
        </div>
      </div>
    </>
  );
}

export default PQPhone;
